import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import SearchJob from "../components/SearchJob"
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import { Seo } from "../components/seo"
import useJobs from "../hooks/useJobs"
const JobSearchTemplate = props => {
  const dataJobs = useJobs("Japanese")
  const markProps = props.pageContext.slug
  const [markItem, setMarkItem] = useState([])
  useEffect(() => {
    dataJobs.forEach(element => {
      element.marks.forEach(elementMark => {
        if (elementMark.name === markProps) {
          setMarkItem(prevState => [...prevState, element])
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <>
      <Seo
        title={`検索結果: ${markProps} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__search">
              <SectionTitle>メキシコ求人・就職・転職情報</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="jobs-listing__job-search">
                <span>検索条件:</span>
                <Link to={`/job-search/${markProps}`}>{markProps}</Link>
                <p>検索条件に合う求人は {markItem.length}件ありました。</p>
              </div>
              <div className="jobs-listing__search--box">
                <SearchJob />
              </div>
            </div>
            <div className="jobs-listing__job-info">
              <hr className="u-line-bottom" />
              {markItem.map(jobs => (
                <JobContent jobs={jobs} />
              ))}
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default JobSearchTemplate

export const query = graphql`
  query markSlug($slug: String) {
    allStrapiMarks(filter: { name: { eq: $slug } }) {
      nodes {
        name
      }
    }
  }
`
